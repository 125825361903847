<template>
<div>
                  <v-card style="min-height:268px; height: auto;" >
                   <v-card-title>
                        <h4 class="table_title">Admission Count Analysis</h4>
                        <v-spacer></v-spacer>
                    </v-card-title>
                    <v-row
        class="fill-height"
        align-content="center"
        justify="center" v-if="!dataLoaded"
      >
        <v-col
          class="text-subtitle-1 text-center"
          cols="12"
        >
         Calculating Count analysis
        </v-col>
        <v-col cols="6">
          <v-progress-linear
            indeterminate
            rounded
            height="6"
            striped
          ></v-progress-linear>
        </v-col>
      </v-row>
    <div v-for="(displayData, index) in loopData" :key="index" >
        <div style="background:" class="container-fluid">
            <div class="row">
                <div class="col-lg-3">
                    <div class="first_child">
                        <div style="display: flex; justify-content: space-between; padding: 10px" class="logo_txt">
                            <div class="txt">
                                <h4>Total Admited</h4>
                                <h2>{{displayData.admited.allcount}}</h2>
                            </div>
                            <div class="logo">
                                <img src="../../assets/Group 283.svg" alt="" srcset="">
                            </div>
                        </div>
                        <div class="utme">
                            <h2>UTME : {{displayData.admited.jambCount}}</h2>
                        </div>
                        <div class="utme">
                            <h2>PS : {{displayData.admited.psCount}}</h2>
                        </div>
                        <div class="direct">
                            <h2>DIRECT ENTRY : {{displayData.admited.deCount}}</h2>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="second_child">
                        <div style="display: flex; justify-content: space-between; padding: 10px" class="logo_txt">
                            <div class="txt">
                                <h4>Accepted Admisison</h4>
                                <h2>{{displayData.accepted.allcount}}</h2>
                            </div>
                            <div class="logo">
                                <img src="../../assets/Group 282.svg" alt="" srcset="">
                            </div>
                        </div>
                        <div class="utme">
                            <h2>UTME : {{displayData.accepted.jambCount}}</h2>
                        </div>
                        <div class="utme">
                            <h2>PS : {{displayData.accepted.psCount}}</h2>
                        </div>
                        <div class="direct">
                            <h2>DIRECT ENTRY : {{displayData.accepted.deCount}}</h2>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="second_child" style="background-color:#D5ECD9">
                        <div style="display: flex; justify-content: space-between; padding: 10px" class="logo_txt">
                            <div class="txt">
                                <h4>Submited Eclearance</h4>
                                <h2>{{displayData.fincleared.allcount}}</h2>
                            </div>
                            <div class="logo">
                                <img src="../../assets/plane.svg" alt="" srcset="" width="50">
                            </div>
                        </div>
                        <div class="utme">
                            <h2>UTME : {{displayData.fincleared.jambCount}}</h2>
                        </div>
                        <div class="utme">
                            <h2>PS : {{displayData.fincleared.psCount}}</h2>
                        </div>
                        <div class="direct">
                            <h2>DIRECT ENTRY : {{displayData.fincleared.deCount}}</h2>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="third_child">
                        <div style="display: flex; justify-content: space-between; padding: 10px" class="logo_txt">
                            <div class="txt">
                                <h4>Total Cleared</h4>
                                <h2>{{displayData.cleared.allcount}}</h2>
                            </div>
                            <div class="logo">
                                <img src="../../assets/Group 279.svg" alt="" srcset="">
                            </div>
                        </div>
                        <div class="utme">
                            <h2>UTME : {{displayData.cleared.jambCount}}</h2>
                        </div>
                        <div class="utme">
                            <h2>PS : {{displayData.cleared.psCount}}</h2>
                        </div>
                        <div class="direct">
                            <h2>DIRECT ENTRY : {{displayData.cleared.deCount}}</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
                  </v-card>
</div>
</template>

<script>
import axios from 'axios'

export default {
    data() {
        return {
            loopData: [], dataLoaded:false
        }
    },
    async beforeMount() {
        this.fetchData();
    },

    methods: {
        async fetchData() {
            const url = process.env.VUE_APP_ADMISSIONS_V1_API_ENDPOINT + '/getAdmitedInfo';
            const admissionAnalysis = await axios({
                method: 'POST',
                url: url,
                data: {
                    session: '2021-2022'
                }
            }).catch(e => {
                console.log(e);
                alert('Something went wrong')
                next(false)
            })
            if (admissionAnalysis.data.code == '00') {
                console.log(admissionAnalysis.data.data)
                this.loopData = admissionAnalysis.data.data
                this.dataLoaded = true
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.first_child {
    width: 100%;
    height: auto;
    background: #FDF3D4;
    border: 1px solid #F0F2F3;
    box-sizing: border-box;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding-bottom: 20px;

    .txt {
        h4 {
            font-family: Montserrat;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 20px;
            display: flex;
            align-items: center;
            color: #4F4F4F;
        }

        h6 {
            font-family: Montserrat;
            font-style: normal;
            font-weight: 600;
            font-size: 10px;
            line-height: 12px;
            display: flex;
            align-items: center;
            text-align: center;
            color: #4F4F4F;
        }

        h2 {
            font-family: Montserrat;
            font-style: normal;
            font-weight: bold;
            font-size: 22px;
            line-height: 27px;
            display: flex;
            align-items: center;
            text-align: center;

            color: #4F4F4F;
        }
    }
}

.second_child {
    width: 100%;
    height: auto;
    background: #D6ECF5;
    border: 1px solid #F0F2F3;
    box-sizing: border-box;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding-bottom: 20px;

    .txt {
        h4 {
            font-family: Montserrat;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 20px;
            display: flex;
            align-items: center;
            color: #4F4F4F;
        }

        h6 {
            font-family: Montserrat;
            font-style: normal;
            font-weight: 600;
            font-size: 10px;
            line-height: 12px;
            display: flex;
            align-items: center;
            text-align: center;
            color: #4F4F4F;
        }

        h2 {
            font-family: Montserrat;
            font-style: normal;
            font-weight: bold;
            font-size: 22px;
            line-height: 27px;
            display: flex;
            align-items: center;
            text-align: center;
            color: #4F4F4F;
        }
    }
}

.third_child {
    width: 100%;
    height: auto;
    background: #FFFFFF;
    border: 1px solid #F0F2F3;
    box-sizing: border-box;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding-bottom: 20px;

    .txt {
        h4 {
            font-family: Montserrat;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 20px;
            display: flex;
            align-items: center;
            color: #4F4F4F;
        }

        h6 {
            font-family: Montserrat;
            font-style: normal;
            font-weight: 600;
            font-size: 10px;
            line-height: 12px;
            display: flex;
            align-items: center;
            text-align: center;
            color: #4F4F4F;
        }

        h2 {
            font-family: Montserrat;
            font-style: normal;
            font-weight: bold;
            font-size: 22px;
            line-height: 27px;
            display: flex;
            align-items: center;
            text-align: center;

            color: #4F4F4F;
        }
    }
}
.utme {
    width: 100%;
    height: 30px;
    // background: #D6ECF5;
    text-align: center;
    padding-top: 10px;
    margin-left: 10px;

    h2 {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 10px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #006794;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 0px 10px;
    }
}

.direct {
    width: 100%;
    height: 30px;
    // background: #EEEEEE;
    text-align: center;
    padding-top: 10px;
    margin-left: 10px;

    h2 {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 12px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #4F4F4F;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 0px 10px;
    }
}


</style>

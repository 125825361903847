<template>
<div class="home">
    <div class="row">
        <div class="col-md-12" style="margin-top:70px !important; height:70px !important; padding:30px 0 0px 10px; position:fixed; z-index: 1; background:#fff; border-bottom: solid 1px #ccc">
            <span style="color:#777; font-size:18px;padding:10px;">Supplementary Shop Report</span>
        </div>
    </div>
    <div style="background: #f5f5f5; margin-top:180px" class=" container-fluid">
        <div class="menu_text py-2 px-4" v-if="spaceanalysis ==''">
            Loading space analysis ....
        </div>
        <div class="menu_text py-2 px-4" v-if="spaceanalysis !=''">
            <div class="row">
            <div class="col-md-4" style="" v-for="(spaces,index) in spaceanalysis" :key="index">
            <v-card style="padding:20px">
Dept. : {{spaces.department}}<br>
Space remaining. : {{spaces.spaces}}
            </v-card>
            </div>
            </div>
        </div>
        <div class="menu_text py-2 px-4" style="background: #D6ECF5">
            <h6>
                Select the Filter Field
            </h6>
        </div>
        <div style="background: #fff;" class=" mb-10 pt-10 px-10 filter">
            <div class="row">
                <div class="col-lg-6">
                    <input type="text" v-model="registration" class="form-control" id="regno" placeholder="Registration Number">
                </div>
                <div class="col-lg-6">
                    <v-autocomplete clearable item-text="name" item-value="_id" v-model="department" :items="departments"    label="Select Department"
> </v-autocomplete>

                </div>
            </div>
            <div class="row">
                <div class="col-lg-6">
                    <v-menu v-model="fromto" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="fromdate" label="Date From" append-icon="mdi-calendar" readonly outlined v-bind="attrs" v-on="on" clearable></v-text-field>
                        </template>
                        <v-date-picker v-model="fromdate" @input="menu2 = false"></v-date-picker>
                    </v-menu>
                </div>
                <div class="col-lg-6">
                    <v-menu v-model="tofrom" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="todate" label="Date To" append-icon="mdi-calendar" readonly outlined v-bind="attrs" v-on="on" clearable></v-text-field>
                        </template>
                        <v-date-picker v-model="todate" @input="menu = false"></v-date-picker>
                    </v-menu>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8"></div>
                <div style="text-align:right" class="col-lg-4">
                    <button @click="searchShop()" class="next_button">Search</button>
                </div>
            </div>
        </div>
        <div class="row" v-if="desserts !=''">
            <div class="col-lg-12">
                <div class="table_clear">
                    <v-card>
                        <v-card-title>
                            <h7 class="table_title" style="color:#666; padding-left:15px">Shopping List</h7>
                            <v-spacer></v-spacer>
                            <button @click="csvExport(csvData)" class="download_b" type="submit"> <span style="margin-right: 4px"></span> Download</button>
                            <v-pagination v-model="clearancepage" :length="clearancepageCount"></v-pagination>
                        </v-card-title>
                        <v-data-table  :page.sync="clearancepage"  :headers="headers" :items="desserts" items-per-page="100" :search="search" class="px-5">
                        <template  v-slot:item.sn="{item}">
{{ desserts.indexOf(item)+1 }}
</template>
                        <template v-slot:item.shop="{item}">
              <span>{{item.department.name}}</span>
          </template>
          <template v-slot:item.surname="{item}">
              <span>{{item.regNumberID.surname}}</span>
          </template>
          <template v-slot:item.name="{item}">
              <span>{{item.regNumberID.name}}</span>
          </template>
          <template v-slot:item.othernames="{item}">
              <span>{{item.regNumberID.othernames}}</span>
          </template>
          <template v-slot:item.jambscore="{item}">
              <span>{{item.regNumberID.total}}</span>
          </template>
                        </v-data-table>
                    </v-card>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import Swal from "sweetalert2";
import axios from 'axios'

export default {
    data: () => ({
        search: '',
        registration: '',
        shoppedFor: '',
        score: '',
        departments: [],
        department:'',
        fromto: '',tofrom:'',spaceanalysis:'',
        regNumberID:{surname:'',name:'',othernames:'',total:''},
        headers: [

            {
                text: 'SN',
                align: 'start',
                sortable: false,
                value: 'sn',
                class: 'grey lighten-4'

            },
            {
                text: 'Reg Number',
                value: 'regnumber',
                class: 'grey lighten-4'

            },
            {
                text: 'Surname',
                value: 'surname',
                class: 'grey lighten-4'

            },
            {
                text: 'Name',
                value: 'name',
                class: 'grey lighten-4'

            },
            {
                text: 'Other Name',
                value: 'othernames',
                class: 'grey lighten-4'

            },
            {
                text: 'Shoped For',
                value: 'shop',
                class: 'grey lighten-4'

            },
            {
                text: 'Date Shoped',
                value: 'dateShoped',
                class: 'grey lighten-4'

            },
            {
                text: 'Jamb Score',
                value: 'jambscore',
                class: 'grey lighten-4'

            },
        ],
        desserts: [],

        // DATE PICKER
        fromdate: '',
        

        todate: '',
        menu: '',

        load: 'Loading...',
        loading: false,
        regno: null,

    }),

    computed: {
       csvData() {
           this.desserts.forEach(element => {
               delete element._id; delete element.__v; delete element.paid; delete element.status

           });
            return this.desserts.map(item => ({
                ...item, department: item.department.name, surname : item.regNumberID.surname, name : item.regNumberID.name, othernames : item.regNumberID.othernames
            }));
        },

    },
    beforeMount() {
        this.admissionanalysis(this.$router.currentRoute.params.response);
    },
    mounted(){
this.spaceAnlys()
this.hideActivityLoader()
    },
    methods: {
        ShowActivityLoader() {    
  if (document.querySelector("#pleaseWaitDialog") == null) {
      var modalLoading = '<div class="modal" id="pleaseWaitDialog" data-backdrop="static" data-keyboard="false" role="dialog" style="margin-top:40vh">\
          <div class="modal-dialog" style="background-color:transparent;border:none">\
              <div class="modal-content" style="background-color:transparent; text-align:center;border:none">\
                  <div class="modal-body">\
                      <div class="spinner-border" role="status" style="color:#fff">\
  <span class="sr-only"></span>\
</div>\
<div style="color:#fff">Loading ...</div>\
                  </div>\
              </div>\
          </div>\
      </div>';
      $(document.body).append(modalLoading);
  }

  $("#pleaseWaitDialog").modal("show");
},
hideActivityLoader() {
  $("#pleaseWaitDialog").modal("hide");
},
        admissionanalysis(x) {
            console.log(x.message);
            this.departments = x.message
        },
        // ANALYSIS
        async spaceAnlys() {
            this.ShowActivityLoader()
            const url = process.env.VUE_APP_ADMISSIONS_V1_API_ENDPOINT + '/shopanalysis'
            const findAdmited = await axios({
                    method: 'GET',
                    url: url,
                   })
                .then(response => {
                    const responses = response.data
               this.spaceanalysis = responses.message  
               console.log(this.spaceanalysis)   
this.hideActivityLoader();
                })
           
        },
        // SHOP REPORT
        async searchShop() {
            this.ShowActivityLoader()
            const url = process.env.VUE_APP_ADMISSIONS_V1_API_ENDPOINT + '/shopreport'
            const findAdmited = await axios({
                    method: 'POST',
                    url: url,
                    data: {
                        department: this.department,
                        regNumber: this.registration,
                        from :this.fromdate,
                        to: this.todate
                    }
                })
                .then(response => {
                    const responses = response.data
                    console.log(responses);
               this.desserts = responses.message   
               if(this.desserts == ''){
                    Swal.fire({
                    icon: 'info',
                    title: 'Shopping List',
                    text: 'No data found. This means we could not find any shopping list that matches you filter criteria ',
                })
               }  
console.log(responses);
this.hideActivityLoader();
                })
           
        },

        // Download Table
        csvExport(arrData) {
            let csvContent = "data:text/csv;charset=utf-8,";
            
            csvContent += [
                    Object.keys(arrData[0]),
                    ...arrData.map(item => Object.values(item))
                ]
                // .trim()
                .join("\n")
                .replace(/(^\[)|(\]$)/gm, "");

            const data = encodeURI(csvContent);
            const link = document.createElement("a");
            link.setAttribute("href", data);
            link.setAttribute("download", "registered.csv");
            link.click();
        }
    },

    watch: {
        loading(val) {
            if (!val) return
            setTimeout(() => (this.loading = false), 3000)
        },
    },
}
</script>

<style lang="scss" scoped>
.form-control {
    width: 100%;
    height: 55px;
    background: #FFFFFF;
    border: 1px solid #E8E9EC;
    box-sizing: border-box;
    border-radius: 3px;
}

.select {
    width: 100%;
    height: 56px;
    border: 1px solid #E8E9EC;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 15px;
    border-radius: 4px;
}

.menu_text {

    h6 {
        font-family: Proxima Nova;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 16px;
        color: #4F4F4F;
    }

    h4 {
        font-family: Proxima Nova;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 16px;
        color: #4F4F4F;
    }
}

.next_button {
    justify-content: center;
    align-items: center;
    padding: 15px;
    width: 60%;
    height: 45px;
    background: #006794;
    border-radius: 3px;
    color: #FFFFFF;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 16px;
}

.download_b {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 150px;
    height: 38px;
    background: #D6ECF5;
    border-radius: 3px;

    font-family: Proxima Nova;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    color: #006794;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0px 5px;
}
</style>

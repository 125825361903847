<template>
  <v-app>
    <!-- <v-app-bar
      app
      color="#FF735E"
      dark
    >
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png"
          transition="scale-transition"
          width="40"
        />

        <v-img
          alt="Vuetify Name"
          class="shrink mt-1 hidden-sm-and-down"
          contain
          min-width="100"
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-name-dark.png"
          width="100"
        />
      </div>

      <v-spacer></v-spacer>

      <v-btn
        href="https://github.com/vuetifyjs/vuetify/releases/latest"
        target="_blank"
        text
      >
        <span class="mr-2">Latest Release</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
    </v-app-bar> -->

    <v-main style="background-color:#F5F5F5">

      <router-view/> 
    </v-main>
  </v-app>
</template>

<script>
import "bootstrap/dist/css/bootstrap.css"
export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>
<style lang='scss' >
.title{
  color:#666
}
.home{
 margin-left:300px;
}
.form-control {
    width: 100%;
    height: 55px;
    background: #FFFFFF;
    border: 1px solid #E8E9EC;
    box-sizing: border-box;
    border-radius: 3px;
}


.download_b {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 150px;
    height: 38px;
    background: #D6ECF5;
    border-radius: 3px;

    font-family: Proxima Nova;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    color: #006794;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0px 5px;
}
.menu_text {

    h6 {
        font-family: Proxima Nova;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 16px;
        color: #4F4F4F;
    }

    h4 {
        font-family: Proxima Nova;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 16px;
        color: #4F4F4F;
    }
}

.table_title {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #2186B5;
}
@media only screen and (max-width: 768px) {
.home{
padding-top:0; margin-left:0 !important
}
}
  
</style>
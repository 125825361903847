<template>
<v-row style="margin:0 !important; padding:0 !important">
    <v-col cols="12" style="">
        <div class="row">
            <div class="col-md-10" style="font-size:20px; padding:20px 0 0 20px">Admin User </div>
            <div class="col-md-2">
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Search Admin User" single-line hide-details></v-text-field>
            </div>
        </div>
        <v-data-table :headers="headers" :items="desserts" sort-by="calories" class="elevation-1 mt-6" :search="search">
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title style="font-size:16px">List Admin Users</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialog" width="500px">
                        <template v-slot:activator="{ attrs }">

                            <v-btn class="mx-2" fab dark color="#006794" v-bind="attrs" small @click="searchAdmin">
                                <v-icon>
                                    mdi-plus
                                </v-icon>
                            </v-btn>

                        </template>
                        <v-card>
                            <v-card-text>
                                <v-container>
                                    <v-row>
                                        <v-col class="col-md-12">
                                            <v-col v-if="valid1">
                                                <!-- <v-avatar sm="6" md="4" color="indigo">
                                                        <v-icon dark>
                                                            mdi-account-circle
                                                        </v-icon>
                                                    </v-avatar> -->
                                                <h5 style="text-align:left">Search Admin</h5><br>
                                                <!-- <v-form ref="form" @submit.prevent="submit"> -->
                                                <v-text-field v-model="email" label="Enter staff cirmsID/staffId/Email" outlined></v-text-field>
                                                <v-btn medium color="#006794" dark class=" pa-3 elevation-10" @click="submit" type="submit">
                                                    Next
                                                </v-btn>
                                                <!-- </v-form> -->
                                            </v-col>

                                            <v-spacer></v-spacer>
                                            <v-col v-if="valid">

                                                <h5 style="text-align:left">Admin Information</h5><br>

                                                <v-text-field v-model="surname" type="text" label="surname" readonly></v-text-field>
                                                <v-text-field v-model="name" type="text" label="name" readonly></v-text-field>
                                                <v-text-field v-model="othernames" type="text" label="othername" readonly></v-text-field>
                                                <v-text-field v-model="staffid" label="staff id" readonly></v-text-field>
                                                <v-select :items="items" item-text="name" item-value="id" v-model="type" label="Select Role"></v-select>

                                                <!-- <v-select v-model="type" :items="items" label="Select Admin Type" required>
                                                    <template slot="item" slot-scope="data" >
  <v-list-tile-content>
    <v-list-tile-title @click="getItemText(data.item.name)" v-html="data.item.name"></v-list-tile-title>
  </v-list-tile-content>
</template>
                                                </v-select> -->

                                                <v-card-actions>
                                                    <v-spacer></v-spacer>
                                                    <v-btn medium color="#006794" dark class=" pa-3 elevation-10" @click="save" type="submit">
                                                        Add Admin
                                                    </v-btn>
                                                    <v-btn color="blue darken-1" text @click="close">
                                                        Cancel
                                                    </v-btn>
                                                    <!-- <v-btn color="blue darken-1" text @click="save">
                                                        Save
                                                    </v-btn> -->

                                                </v-card-actions>

                                            </v-col>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>

                        </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogDelete" max-width="500px">
                        <v-card>
                            <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                                <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
                <!-- <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)">
              mdi-delete
            </v-icon> -->
                <v-icon small @click="redirect(item)">
                    mdi-cogs
                </v-icon>
            </template>
            <template v-slot:no-data>
               
            </template>
        </v-data-table>
    </v-col>
</v-row>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';
export default {
    data: () => ({
        search: '',
        surname: '',
        names: '',
        othernames: '',
        staffid: '',
        status: '',
        dialog: false,
        valid: false,
        valid1: true,
        type: '',
        dialogDelete: false,
        email: '',
        adminDatas: null,
        headers: [{
                text: 'S/N',
                align: 'start',
                value: 'sn',
            },
            {
                text: 'Staff id',
                value: 'skola_id'
            },
            {
                text: 'Tittle',
                value: 'prefix',
            },
            {
                text: 'Surname',
                value: 'surname',
            },
            {
                text: 'Name',
                value: 'name'
            },

            {
                text: 'OtherNames',
                value: 'othernames'
            },
            {
                text: 'Email',
                value: 'email'
            },

            {
                text: 'Type',
                value: 'admin_type_name'
            },
            {
                text: 'Status',
                value: 'status'
            },
            {
                text: 'Actions',
                value: 'actions',
                sortable: false
            },
        ],
        items: [{
                "name": 'Admission',
                "id": 100
            },
            {
                "name": 'Bussary',
                "id": 200
            },
            {
                "name": 'Department',
                "id": 300
            },
            {
                "name": 'Exams and record',
                "id": 400
            },
            {
                "name": 'Faculty',
                "id": 500
            },
            {
                "name": 'General Admin',
                "id": 1000
            },
            {
                "name": 'Lecturer',
                "id": 700
            },
            {
                "name": 'Student Affairs',
                "id": 800
            }
        ],
        desserts: [],
        editedIndex: -1,
        editedItem: {
            surname: '',
            names: '',
            othernames: '',
            staffid: '',
            type: '',
            status: '',
        },

    }),

    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
    },

mounted(){
console.log('chuka')
this.desserts =  this.$router.currentRoute.params.response.message
console.log(this.$router.currentRoute.params);
},

    methods: {
        redirect(item) {
            this.$router.push(`/settings?id=${item.staffid}`);
        },
        show() {
            this.valid = true
        },
        show1() {
            this.valid1 = false
        },

        editItem(item) {
            this.editedIndex = this.desserts.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialog = true
        },

        deleteItem(item) {
            this.editedIndex = this.desserts.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true
        },

        deleteItemConfirm() {
            this.desserts.splice(this.editedIndex, 1)
            this.closeDelete()
        },

        close() {

            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },
        searchAdmin() {
            this.dialog = true;
            this.valid = false;
            this.valid1 = true;
        },
        async submit() {
            if (!this.email) {
                Swal.fire({
                            text: 'Please Enter a Valid Email/StaffId',
                            title: 'Oops..',
                            icon: 'info'
                        })

            } else {
                await axios.post('http://localhost:5003/v1/admin/fetchExtrenalAdmin', {
                    staffId: this.email
                }).then(res => {
                    if (res.data.code == '00') {
                        console.log(res.data);
                        const response = res.data.message;
                        this.adminDatas = response;
                        const {
                            other_details,
                            personal_details
                        } = response;
                        const names = personal_details.othernames.split(' ');
                        this.surname = personal_details.surname;
                        this.name = names[0];
                        this.othernames = names[1];
                        this.staffid = personal_details.user_id;
                        this.status = other_details.staff_status;
                        this.valid = true;
                        this.valid1 = false;
                    } else {
                        return Swal.fire({
                            text: res.data.message,
                            title: 'Oops..',
                            icon: 'error'
                        })
                    }
                }).catch(err => console.log(err));

            }
        },

        save() {
            if (!this.surname || !this.name  || !this.staffid || !this.type) {
                Swal.fire('Oops..', 'Make sure you entered all fields properly to Add New admin', 'info');
                this.email = '';
            } else {
                const {
                    personal_details,
                    other_details,
                    work_details
                } = this.adminDatas;

                console.log(this.type);
                const data = {
                    name: this.name,
                    surname: this.surname,
                    othernames: this.othernames,
                    email: personal_details.email,
                    staffid: work_details.staff_no,
                    status: this.status,
                    admin_type: this.type,
                    prefix: personal_details.prefix,
                    sex: personal_details.sex,
                    cadre: work_details.cadre,
                    teaching_status: other_details.teaching_status,
                    hod_dean_status: other_details.hod_dean_status,
                    facdir_code: other_details.facdir_code,
                    depunit_code: other_details.depunit_code,
                    cirms_id: personal_details.user_id,
                    phone: personal_details.phone,
                }
                axios.post('http://localhost:5003/v1/admin/createadmin', data).then(res => {
                    if (res.data.code == '00') {
                        data.skola_id = data.cirms_id
                        this.desserts.push(data)
                        this.valid = false;
                        this.dialog = false;
                        Swal.fire({
                            icon: 'success',
                            text: res.data.message,
                            title: 'Successful',
                        });
                    } else {
                        this.valid1 = true;
                        this.valid = false;
                        return Swal.fire({
                            icon: 'error',
                            text: res.data.message,
                            title: 'Error'
                        });

                    }

                }).catch(() => {
                    return Swal.fire({
                        icon: 'error',
                        text: 'Something went wrong',
                        title: 'Error'
                    })
                })
                this.email = '';
                this.type = '';
            }
        },
    },

}
</script>

<style scoped>
.v-row {
    margin: 0 !important;
    padding: 0 !important;
}
</style>

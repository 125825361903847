<template>
<div class="home">
    <div class="row">
        <div class="col-md-12" style="margin-top:70px !important; height:70px !important; padding:30px 0 0px 10px; position:fixed; z-index: 1; background:#fff; border-bottom: solid 1px #ccc">
            <span style="color:#777; font-size:18px;padding:10px;">E-clearance Report</span>
        </div>
    </div>

    <div style="background: #f5f5f5; margin-top:180px" class=" container-fluid">
        <div class="menu_text py-2 px-4" style="background: #D6ECF5">
            <h6>
                Search Filter
            </h6>
        </div>
        <div style="background: #fff;" class=" mb-10 pt-10 px-10 filter">
            <div class="row">
                <div class="col-lg-12 ">

                    <div class="col-lg-4" style="margin:auto">
                        <v-autocomplete clearable item-text="name" item-value="slug" v-model="program" :items="programs" label="Select Programe" outlined> </v-autocomplete>

                    </div>
                </div>
                <div class="col-lg-12 pullup">

                    <div class="col-lg-4" style="margin:auto">
                        <v-autocomplete clearable item-text="name" item-value="_id" v-model="year" :items="admissionYear" label="Admission Year" outlined> </v-autocomplete>

                    </div>
                </div>
                <div class="col-lg-12 pullup">

                    <div class="col-lg-4" style="margin:auto">
                        <v-autocomplete clearable item-text="name" item-value="code" v-model="clearanceStatus" :items="cStatus" label="Clearance Status" outlined> </v-autocomplete>

                    </div>
                </div>
                <div class="col-lg-12 pullup">
                    <div class="col-lg-4" style="margin:auto">
                        <v-text-field type="text" v-model="regNumber" class="" id="regno" placeholder="Entry Number" clearable label="Entry Number" outlined></v-text-field>
                    </div>
                </div>

                <div class="col-lg-12 pullup">

                    <div class="col-lg-4" style="margin:auto">
                        <v-autocomplete clearable item-text="name" item-value="name" v-model="entry_mode" :items="entryMode" label="Select Entry Mode" outlined> </v-autocomplete>

                    </div>
                </div>
                <div class="col-lg-12 pullup">
                    <div class="col-lg-4" style="margin:auto">
                        <v-autocomplete clearable item-text="name" item-value="_id" v-model="faculty" :items="faculties" label="Select Faculty" outlined> </v-autocomplete>
                    </div>
                </div>

                <div class="col-lg-12 pullup">
                    <div class="col-lg-4" style="margin:auto">
                        <v-autocomplete clearable item-text="name" item-value="_id" v-model="department" :items="departments" label="Select Department" outlined> </v-autocomplete>
                    </div>
                </div>
                <div class="col-lg-12 pullup">
                    <div class="col-lg-4" style="margin:auto">
                        <v-btn @click="filterAdmittedStudents" class="_btn" block>Search</v-btn>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="table_clear">
                    <v-card>
                        <v-card-title>
                            <h7 class="table_title" style="color:#666; padding-left:15px">Eclearnace Report</h7>
                            <v-spacer></v-spacer>
                            <button @click="csvExport(csvData3)" class="download_b" type="submit"> <span style="margin-right: 4px"></span> Download</button>
                            <v-pagination v-model="clearancepage" :length="clearancepageCount"></v-pagination>
                        </v-card-title>
                        <v-data-table :headers="headers" :items="datas">
                            <template v-slot:item.sn="{item}">
                                {{ datas.indexOf(item)+1 }}
                            </template>
                            <template v-slot:item.status="{item}">
                                <span v-if="item.status == 1">Not Paid Acceptance</span>
                                <span v-if="item.status == 2">Paid Acceptance</span>
                                <span v-if="item.status == 3">Biometric Finger</span>
                                <span v-if="item.status == 4">Biometric Facial</span>
                                <span v-if="item.status == 5">Submited Eclearnce</span>
                                <span v-if="item.status == 20">Form is on Reset</span>
                                <span v-if="item.status == 6">Cleared Online</span>

                            </template>
                            <template v-slot:item.dep="{item}">
                                <span>{{item.department.name}}</span>
                            </template>
                            <template v-slot:item.fac="{item}">
                                <span>{{item.faculty.name}}</span>
                            </template>
                        </v-data-table>
                    </v-card>
                </div>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
    data() {
        return {
            program: "",
            programs: [],
            faculty: "",
            faculties: [],
            department: "",
            departments: [],
            year: "",
            admissionYear: ["2021-2022"],
            cStatus: [{
                name: "All",
                code: ''
            }, {
                name: "To pay Acceptance",
                code: '1'
            }, {
                name: "Paid Acceptance",
                code: '2'
            }, {
                name: "Biometric Face",
                code: '3'
            }, {
                name: "Biometric Finger",
                code: '4'
            }, {
                name: "Submited E-clearnce Form",
                code: '5'
            }, {
                name: "Form Is On Reset",
                code: '20'
            }, {
                name: "Cleared Online",
                code: '6'
            }],
            regNumber: "",
            entry_mode: "",
            entryMode: [],
            menu1: false,
            date: "",
            date1: "",
            facultyArr: [],
            departmentArr: [],
            menu2: false,
            headers: [{
                    text: "Entry Number",
                    value: "regnumber",
                },
                {
                    text: "School Reg",
                    value: "schoolReg",
                },
                {
                    text: "Surname",
                    value: "surname",
                },
                {
                    text: "Name",
                    value: "name",
                },
                {
                    text: "Othernames",
                    value: "othername",
                },
                {
                    text: "Faculty",
                    value: "fac",
                },
                {
                    text: "Department",
                    value: "dep",
                },

                {
                    text: "Entry Point",
                    value: "entry_mode",
                },
                {
                    text: "Cleared By",
                    value: "clearedBy",
                },
                {
                    text: "Clearance Status",
                    value: "status",
                },
            ],
            datas: [],
        };
    },
    watch: {
        faculty(val) {
            this.departments = [];
            const departments = this.departmentArr.filter((el) => el.faculty == val);
            departments.forEach((el) => {
                this.departments.push(el);
            });
            // console.log(departments);
        },
    },
    
    computed:{
        filteredItemsthree: function () {
            return this.filterItemsthree(this.datas);
        },
        csvData3() {
            return this.datas.map(item => ({
                ...item,
                department:item.department.name,
                faculty:item.faculty.name
            }));
        },
    },
    beforeMount() {
        
        this.recieveDept(this.$router.currentRoute.params.response)
        this.recieveFac(this.$router.currentRoute.params.response1)
        this.recieveProg(this.$router.currentRoute.params.response2)
        this.recieveEmode(this.$router.currentRoute.params.response3)

        this.fetchProgram();
        this.fetchFaculty();
        this.fetchEntryFrom();
        this.fetchDepartments();
        this.fetchEntryFrom();
    },
    methods: {
        filterItemsthree: function (arr, query) {
            return arr.filter(function (item) {
                let dept = item.department.name.toString().toLowerCase();
                let facul = item.faculty.name.toString().toLowerCase();
                return (dept && facul);
            })
        },
        csvExport(arrData) {
            if (this.filteredItemsthree.length == 0) {
                Swal.fire({
                    icon: "info",
                    text: "Table Is Empty",
                    width: 400,
                });
            } else {
                let csvContent = "data:text/csv;charset=utf-8,";
                csvContent += [
                        Object.keys(arrData[0]),
                        ...arrData.map(item => Object.values(item))
                    ]
                    // .trim()
                    .join("\n")
                    .replace(/(^\[)|(\]$)/gm, "");

                const data = encodeURI(csvContent);
                const link = document.createElement("a");
                link.setAttribute("href", data);
                link.setAttribute("download", "studentlist.csv");
                link.click();
            }
        },
        ShowActivityLoader() {
            if (document.querySelector("#pleaseWaitDialog") == null) {
                var modalLoading = '<div class="modal" id="pleaseWaitDialog" data-backdrop="static" data-keyboard="false" role="dialog" style="margin-top:40vh">\
          <div class="modal-dialog" style="background-color:transparent;border:none">\
              <div class="modal-content" style="background-color:transparent; text-align:center;border:none">\
                  <div class="modal-body">\
                      <div class="spinner-border" role="status" style="color:#fff">\
  <span class="sr-only"></span>\
</div>\
<div style="color:#fff">Loading ...</div>\
                  </div>\
              </div>\
          </div>\
      </div>';
                $(document.body).append(modalLoading);
            }

            $("#pleaseWaitDialog").modal("show");
        },
        hideActivityLoader() {
            $("#pleaseWaitDialog").modal("hide");
        },
        // Recieve thee dept payload from the router call before route
        recieveProg(x) {
            console.log(x.message);
            this.programs = x.message
        },
        recieveDept(x) {
            console.log(x.message);
            this.departmentArr = x.message
        },
        // Recieve thee fact payload from the router call before route
        recieveFac(x) {
            console.log(x.message);
            this.faculties = x.message;

        },
        recieveEmode(x) {
            console.log(x.message);
            this.entryMode = x.message;

        },
        async fetchProgram() {
            try {
                const res = await axios.get("http://127.0.0.1:5000/fetchprograms");
                if (res.data.status == "success") {
                    const data = res.data.data;
                    const arr = [];
                    for (const program of data) {
                        arr.push({
                            text: program.program,
                            value: program.slug
                        });
                    }
                    this.programs.push(...arr);
                    console.log("HERE", this.programs);
                }
            } catch (error) {
                console.log(error);
            }
        },
        async fetchFaculty() {
            try {
                const res = await axios.get("http://127.0.0.1:5000/fetchallfaculty");
                // console.log(res);
                if (res.data.code == "00") {
                    const arr = [];
                    this.facultyArr = res.data.data;
                    for (const faculty of res.data.data) {
                        arr.push({
                            text: faculty.name,
                            value: faculty._id
                        });
                    }
                    this.faculties.push(...arr);
                }
            } catch (error) {
                console.log(error);
            }
        },
        async fetchDepartments() {
            try {
                const res = await axios.get("http://127.0.0.1:5000/getalldepartments");
                if (res.data.code == "00") {
                    this.departmentArr = res.data.data;
                    console.log(res);
                }
            } catch (error) {
                console.log(error);
            }
        },
        async filterAdmittedStudents() {
            this.ShowActivityLoader();
            try {
                if (!this.year || !this.program)
                    return Swal.fire({
                        icon: "info",
                        text: "Please select program and year",
                    });
                // {
                //   entry_mode: this.entryPoint, year: this.year, faculty:
                //   this.faculty, department: this.department, program:
                //   this.program, regNumber: this.regNumber, date: this.date, date1: this.date1
                // }
                const data = {};

                if (this.entry_mode) {
                    data["entryPoint"] = this.entry_mode;
                }

                if (this.faculty) {
                    data["faculty"] = this.faculty;
                }

                if (this.department) {
                    data["department"] = this.department;
                }
                if (this.entry_mode) {
                    data["entry_mode"] = this.entry_mode;
                }
                if (this.regNumber) {
                    data["regnumber"] = this.regNumber;
                }
                if (this.clearanceStatus) {
                    data["status"] = this.clearanceStatus;
                }
                const url = process.env.VUE_APP_ADMISSIONS_V1_API_ENDPOINT + '/eclearnceinfo'

                const res = await axios.post(
                    url, {
                        program: this.program.split(" ").join("_"),
                        year: this.year,
                        data
                    }
                );
                console.log(res);
                if (res.data.code == "00") {
                    if (res.data.message.length == 0) {
                        Swal.fire({
                            icon: "info",
                            text: "Students Not Found"
                        });
                    }
                    this.datas = res.data.message;

                }
                this.hideActivityLoader()

            } catch (error) {
                this.hideActivityLoader()
                console.log(error);
            }
        },
        async fetchEntryFrom() {
            try {
                const res = await axios.get("http://127.0.0.1:5000/fetch-entry-from");
                console.log("Entry0", res);
                if (res.data.code == "00") {
                    const arr = [];
                    res.data.data.forEach((el) => {
                        arr.push(el.name.toUpperCase());
                    });
                    this.entryMode.push(...arr);
                }
            } catch (error) {
                console.log(error);
            }
        },
    },
};
</script>

<style scoped>
.pullup {
    margin-top: -50px;
}

._btn {
    background: #21618c !important;
    min-width: 200px;
    margin: 0 auto;
    color: #fff;
    height: 40px;
}

.header {
    background: #d6ecf5;
}

.filter-container {
    padding: 1.5rem;
    margin-top: 4rem;
}
</style>

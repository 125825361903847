<template>
  <div style="background: #f5f5f5; min-height: 500px" class="my-4 ">
    <div class="txt mb-4">
      <p class="pt-2 px-8">Student Information</p>
    </div>
    <div v-if="success" class="pa-12">
      <v-row>
        <v-col class="col-sm-12 col-md-2 col-lg-4"></v-col>
        <v-col class="col-sm-12 col-md-2 col-lg-4">
          <v-form>
            <label for=""
              ><strong>Enter Student Registration Number</strong></label
            >
            <v-text-field
              v-model="regnumber"
              outlined
              label="Enter Reg Number"
            ></v-text-field>
            <v-btn
              style="background: #006794; color: #fff; height: 50px "
              block
              @click="getStudentInfo"
              >Submit</v-btn
            >
          </v-form>
        </v-col>
        <v-col class="col-sm-12 col-md-2 col-lg-4"></v-col>
      </v-row>
    </div>
    <div v-else class="box">
      <v-row>
        <v-col class="col-sm-0 col-md-2 col-lg-2"></v-col>
        <v-col class="col-sm-12 col-md-8 col-lg-6">
          <v-form>
            <v-row class="pa-1">
              <v-col cols="6" class="py-0">
                <label for="">First Name</label>
                <v-text-field
                  outlined
                  label="Enter First Name"
                  v-model="firstname"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="py-0">
                <label for="">Surname</label>
                <v-text-field
                  outlined
                  label="Enter Last Name"
                  v-model="surname"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="py-0">
                <label for="">Other Names</label>
                <v-text-field
                  outlined
                  label="Enter Other Names"
                  v-model="othernames"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="py-0">
                <label for="">Email</label>
                <v-text-field
                  outlined
                  label="Enter Email"
                  v-model="email"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="py-0">
                <label for="">Date of Birth</label>
                <v-text-field
                  outlined
                  label="Enter DOB"
                  v-model="dob"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="py-0">
                <label for="">Gender</label>

                <v-text-field
                  outlined
                  label="Enter sex"
                  v-model="gender"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="py-0">
                <label for="">Nationality</label>
                <v-text-field
                  outlined
                  label="Enter Nationality"
                  v-model="nationality"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="py-0">
                <label for="">State</label>
                <v-text-field
                  outlined
                  label="Enter State"
                  v-model="state"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="py-0">
                <label for="">LGA</label>
                <v-text-field
                  outlined
                  label="Enter LGA"
                  v-model="lga"
                ></v-text-field>
              </v-col>
              <v-col class="py-0" cols="6">
                <label for="">Town</label>
                <v-text-field
                  outlined
                  label="Enter Town"
                  v-model="town"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="py-0">
                <label for="">Residential Address</label>
                <span>
                  <v-text-field
                    outlined
                    label="Enter Residential Address"
                    v-model="residential_address"
                  ></v-text-field>
                </span>
              </v-col>
            </v-row>
          </v-form>
          <div class="my-10 button_procceed">
            <v-btn
              block
              style="background: #006794; color: #fff; height: 50px "
              type="submit"
              @click="update"
            >
              Update Record
            </v-btn>
          </div>
        </v-col>
        <v-col class="col-sm-0 col-md-2 col-lg-2"></v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import axios from "axios";

export default {
  data() {
    return {
      success: true,
      surname: "",
      firstname: "",
      othernames: "",
      regnumber: "",
      email: "",
      dob: "",
      gender: "",
      nationality: "",
      state: "",
      lga: "",
      town: "",
      residential_address: "",
      next_of_kin: "",
    };
  },

  methods: {
    async update() {
      try {
        const data = {
          surname: this.surname,
          firstname: this.firstname,
          othernames: this.othernames,
          email: this.email,
          dob: this.dob,
          gender: this.gender,
          nationality: this.nationality,
          state: this.state,
          lga: this.lga,
          town: this.town,
          residential_address: this.residential_address,
          next_of_kin: this.next_of_kin,
        };
        const url = process.env.VUE_APP_ADMISSIONS_V1_API_ENDPOINT +'/changeClearnceData'
        const res = await axios.post(
          url,
          data
        );
        if (res.data.code == "00") {
          this.success = true;
          this.surname = "";
          this.firstname = "";
          this.othernames = "";
          this.email = "";
          this.dob = "";
          this.gender = "";
          this.nationality = "";
          this.state = "";
          this.lga = "";
          this.town = "";
          this.residential_address = "";
          this.next_of_kin = "";
          return Swal.fire({
            icon: "success",
            title: "Sucessful",
          });
        } else {
          return Swal.fire({
            icon: "error",
            title: "Something went wrong",
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getStudentInfo() {
      try {
        if (!this.regnumber)
          return Swal.fire({ icon: "error", title: "Please Enter Reg Number" });
                  const url = process.env.VUE_APP_ADMISSIONS_V1_API_ENDPOINT +'/find_admited'
        const res = await axios.post(
         url,
          {
                    entryData: this.regnumber,
                    year: '2021-2022'
                }
        );
        if (res.data.code == "00") {
          this.success = false;
          this.regnumber = "";
          this.surname = res.data.message.surname;
          this.firstname = res.data.message.name;
          this.othernames = res.data.message.othername;
          this.email = res.data.message.email;
          this.dob = res.data.message.dob;
          this.gender = res.data.message.gender;
          this.nationality = res.data.message.nationality;
          this.state = res.data.message.state;
          this.lga = res.data.message.lga;
          this.town = res.data.message.town;
          this.residential_address = res.data.message.resident;
          this.next_of_kin = res.data.message.next_of_kin;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.button_procceed {
  text-align: right;

  button {
    justify-content: center;
    align-items: center;
    padding: 15px;
    width: 335px;
    height: 45px;

    background: #006794;
    border-radius: 3px;

    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;

    align-items: center;
    text-align: center;

    color: #ffffff;
  }
}

.waec_jamb {
  h4 {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 114%;
    color: #4f4f4f;
  }
}

.list_item {
  list-style-type: none;
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 114%;
  margin: 10px 0;
  color: #4f4f4f;
}

.list_item2 {
  list-style-type: none;
  margin: 7.5px 0;

  input {
    align-items: center;
    padding: 12px;
    width: 300px;
    height: 40px;
    border-radius: 7px;
    outline: 1px solid #ccc;
    background: #ffffff;
    border: 0.5px solid #4f4f4f;
    box-sizing: border-box;
    border-radius: 4px;
  }
}

.text {
  margin: 10px 0;

  p {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #4f4f4f;
  }
}

.box {
  width: 100%;
  height: auto;
  background: #ffffff;
}

.txt {
  width: 100%;
  height: 44px;
  background: #d6ecf5;

  p {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    color: #006794;
  }
}
</style>

import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/components/Admissiondashboard.vue'
import biometrics from '@/components/biometricsCapture'
import axios from 'axios'
import clearApplicant from '@/components/clearStudent'
import users from '../components/listCreateAdminList.vue'
import ChangeOfCourse from '../components/changeOfCourse.vue'
import ChangeNewData from '../components/ChangeNewData.vue'
import supplementaryReport from '../components/supplementary'
import findAdmited from '../components/listAdmitted'
import eclearnaceReport from '../components/eclearanceList'
import clearancebyDept from '../components/dashboardComponents/clearancebyDept'
import cardAnalytics from '../components/dashboardComponents/cardnalytics'
//import allocatedRegnumb from '../components/'

// import {getLoggedInUser,getCookie,checkMenuEligibility,parseJwt} from '@Appmart/utility'

Vue.use(VueRouter)
function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return false;
}
function ShowActivityLoader() {    
  if (document.querySelector("#pleaseWaitDialog") == null) {
      var modalLoading = '<div class="modal" id="pleaseWaitDialog" data-backdrop="static" data-keyboard="false" role="dialog" style="margin-top:40vh">\
          <div class="modal-dialog" style="background-color:transparent;border:none">\
              <div class="modal-content" style="background-color:transparent; text-align:center;border:none">\
                  <div class="modal-body">\
                      <div class="spinner-border" role="status" style="color:#fff">\
  <span class="sr-only"></span>\
</div>\
<div style="color:#fff">Loading ...</div>\
                  </div>\
              </div>\
          </div>\
      </div>';
      $(document.body).append(modalLoading);
  }

  $("#pleaseWaitDialog").modal("show");
}
function hideActivityLoader() {
  $("#pleaseWaitDialog").modal("hide");
}

function parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}


const routes = [
  
  {
  path: '/admissions/users',
  name: 'users',
  beforeEnter: (to, from, next) => {
          axios.post('http://localhost:5003/v1/admin/listadmin')
            .then(response => {
              if(response.data.code =='00'){
              console.log(response.data);
              to.params.response = response.data;
              next({ params: to.params });
              }
            })
            .catch(e => {
              alert('Something went wrong')
              next(false)
            })
        },
  component: users
},
  {
    path: '/admissions/dashboard',
    name: 'Home',
    // beforeEnter: async (to, from, next) => {
    //   const url = process.env.VUE_APP_ADMISSIONS_V1_API_ENDPOINT+'/getAdmitedInfo'
    //   console.log(url);
    //   /**
    //    * 
    //    * note to do
    //    * Find a way to get session into this place
    //    */
    //    const admissionAnalysis =  await axios({
    //         method: 'POST',
    //         url: url,
    //         data: {
    //             session: '2021-2022'
    //         }
    //     }).catch(e =>{
    //       console.log(e);
    //       alert('Something went wrong')
    //           next(false)
    //     })       

    //     if(admissionAnalysis.data.code =='00'){
    //           to.params.admissionAnalysis = admissionAnalysis.data;
    //           next({ params: to.params });
    //       }else{
    //         alert('Something went wrong')
    //         next(false)
    //       }
    //       },
    component: Home,
    meta:{
requiresAuth:true,check_link:true
    },
  children:[
      {
        path: '/admissions/dashboard',
        components: {default: clearancebyDept,cardAnalytics},
        name: "clearancebyDept"
    }
  ]
  },
  {
    path: '/admissions/changecourse',
    name: 'changeOfCourse',
    meta:{
      requiresAuth:true,check_link:true
          },
          component: ChangeOfCourse
        },
        {
          path: '/admissions/changenewdata',
          name: 'changeNewData',
          meta:{
            requiresAuth:true,check_link:true
                },
                component: ChangeNewData
              },
  {
    path: '/admissions/clearapplicant',
    name: 'clearApplicant',
    meta:{
      requiresAuth:true,check_link:true
          },
    // beforeEnter: async (to, from, next) => {
    //   const url = process.env.VUE_APP_ADMISSIONS_V1_API_ENDPOINT+'/getAdmitedInfo'
    //   console.log(url);
    //   /**
    //    * 
    //    * note to do
    //    * Find a way to get session into this place
    //    */
    //    const admissionAnalysis =  await axios({
    //         method: 'POST',
    //         url: url,
    //         data: {
    //             session: '2021-2022'
    //         }
    //     }).catch(e =>{
    //       console.log(e);
    //       alert('Something went wrong')
    //           next(false)
    //     })       

    //     if(admissionAnalysis.data.code =='00'){
    //           to.params.admissionAnalysis = admissionAnalysis.data;
    //           next({ params: to.params });
    //       }else{
    //         alert('Something went wrong')
    //         next(false)
    //       }
    //       },
    component: clearApplicant
  },
  {
    path: "/biometrics/biometricSuccess",
    name: "BiometricSuccess",
    meta:{
      requiresAuth:true,check_link:false
          },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "biometric" */ "../components/biometricSuccess.vue"
      ),
  },
  {
    path: "/biometrics/biometricError",
    name: "BiometricError",
    meta:{
      requiresAuth:true, check_link:false
          },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "biometric" */ "../components/biometricError.vue"),
  },

////////SUPLEMENTARY VIEW
{
  path: "/admissions/supplemtaryshop",
  name: "supplemtaryshop",
  meta:{
    requiresAuth:true, check_link:false
        },
        beforeEnter: (to, from, next) => {
          const url = process.env.VUE_APP_ADMISSIONS_V1_API_ENDPOINT2+'/get-department'
          axios.post(url)
            .then(response => {
              if(response.data.code =='00'){              
              console.log(response.data);
              to.params.response = response.data;
              next({ params: to.params });
              }
            })
            .catch(e => {
              next(false)
            })
        },
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component:supplementaryReport
},

  {
    path: '/admissions/biometrics',
    name: 'biometrics',
    meta:{
      requiresAuth:true,check_link:true
          },
    // beforeEnter: async (to, from, next) => {
    //   const url = process.env.VUE_APP_ADMISSIONS_V1_API_ENDPOINT+'/getAdmitedInfo'
    //   console.log(url);
    //   /**
    //    * 
    //    * note to do
    //    * Find a way to get session into this place
    //    */
    //    const admissionAnalysis =  await axios({
    //         method: 'POST',
    //         url: url,
    //         data: {
    //             session: 2021
    //         }
    //     }).catch(e =>{
    //       console.log(e);
    //       alert('Something went wrong')
    //           next(false)
    //     })       

    //     if(admissionAnalysis.data.code =='00'){
    //           to.params.admissionAnalysis = admissionAnalysis.data;
    //           next({ params: to.params });
    //       }else{
    //         alert('Something went wrong')
    //         next(false)
    //       }
    //       },
    component: biometrics
  },
  ////////SUPLEMENTARY VIEW
{
  path: "/admissions/findadmited",
  name: "findAdmitted",
  meta:{
    requiresAuth:true, check_link:false
        },
        beforeEnter: (to, from, next) => {
          const url = process.env.VUE_APP_ADMISSIONS_V1_API_ENDPOINT2+'/get-department'                
          const url1 = process.env.VUE_APP_ADMISSIONS_V1_API_ENDPOINT2+'/get-faculty_standalone' 
          const url2 = process.env.VUE_APP_ADMISSIONS_V1_API_ENDPOINT2+'/get-program'
          const url3 = process.env.VUE_APP_ADMISSIONS_V1_API_ENDPOINT2+'/get-entrymode'

          const requestOne = axios.post(url);
          const requestTwo = axios.post(url1);
          const requestThree = axios.post(url2);
          const requestFour = axios.post(url3);

          axios.all([requestOne, requestTwo,requestThree,requestFour])
            .then(axios.spread((...responses) => {
              const responseOne = responses[0]
              const responseTwo = responses[1]
              const responseThree = responses[2]
              const responseFour = responses[3]

              console.log(responseOne);
              console.log(responseTwo);              console.log(responseThree);

              to.params.response = responseOne.data;
              to.params.response1 = responseTwo.data;
              to.params.response2 = responseThree.data;
              to.params.response3 = responseFour.data;


              next({ params: to.params });
            }))
            .catch(e => {
              next(false)
            })
        },
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component:findAdmited
},
{
  path: "/admissions/eclearancereport",
  name: "eclearnaceReport",
  meta:{
    requiresAuth:true, check_link:false
        },
        beforeEnter: (to, from, next) => {
          const url = process.env.VUE_APP_ADMISSIONS_V1_API_ENDPOINT2+'/get-department'                
          const url1 = process.env.VUE_APP_ADMISSIONS_V1_API_ENDPOINT2+'/get-faculty_standalone' 
          const url2 = process.env.VUE_APP_ADMISSIONS_V1_API_ENDPOINT2+'/get-program'
          const url3 = process.env.VUE_APP_ADMISSIONS_V1_API_ENDPOINT2+'/get-entrymode'

          const requestOne = axios.post(url);
          const requestTwo = axios.post(url1);
          const requestThree = axios.post(url2);
          const requestFour = axios.post(url3);

          axios.all([requestOne, requestTwo,requestThree,requestFour])
            .then(axios.spread((...responses) => {
              const responseOne = responses[0]
              const responseTwo = responses[1]
              const responseThree = responses[2]
              const responseFour = responses[3]

              console.log(responseOne);
              console.log(responseTwo);              console.log(responseThree);

              to.params.response = responseOne.data;
              to.params.response1 = responseTwo.data;
              to.params.response2 = responseThree.data;
              to.params.response3 = responseFour.data;
              next({ params: to.params });
            }))
            .catch(e => {
              next(false)
            })
        },
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component:eclearnaceReport
},
]


const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})
 router.beforeEach((to, from, next) => {
  console.log(to);  
  if(to.matched.some(record => record.meta.requiresAuth)) {
    let name = getCookie("-X_Zka");
    console.log(name);
    if(!name){
  window.location.href =`${window.location.origin}/#/auth`;
}else{
  if(to.matched.some(record1 => record1.meta.check_link)) {
    const jwtDecode = parseJwt(name)
    // check if user is eligible for the menu
  var url = process.env.VUE_APP_ADMISSIONS_V1_API_ENDPOINT+"/verifyMenu";
   axios.post(url,{
    staffid:jwtDecode.staffid,
    menuPath:to.fullPath
   })
                .then(response => {
                  if(response.data.code =='00'){
                    console.log(response.data.message);
                    if(response.data.message == true){
                      next()
                    }else{
                      window.location.href =`${window.location.origin}/#/auth`;
                    }
                  }else{
                    window.location.href =`${window.location.origin}/#/auth`;
                    
                  }
                })
                .catch(e => {
                  window.location.href =`${window.location.origin}/#/auth`;
                  alert('Something went wrong')
                })  
  }else{
    next()
  }
}
  }else{
    if(to.matched.some(record => record.meta.check_link)) {

      const jwtDecode = parseJwt(name)
      // check if user is eligible for the menu
    var url = process.env.VUE_APP_ADMISSIONS_V1_API_ENDPOINT+"/verifyMenu";
     axios.post(url,{
      staffid:jwtDecode.staffid,
      menuPath:to.fullPath
     })
                  .then(response => {
                    if(response.data.code =='00'){
                      console.log(response.data.message);
                      if(response.data.message == true){
                        next()
                      }else{
                        window.location.href =`${window.location.origin}/#/auth`;
                      }
                    }else{
                      window.location.href =`${window.location.origin}/#/auth`;
                      
                    }
                  })
                  .catch(e => {
                    window.location.href =`${window.location.origin}/#/auth`;
                    alert('Something went wrong')
                  })    }else{
    next()
    }
  }
})
export default router



function checkMenu(name){
  const jwtDecode = parseJwt(name)
  // check if user is eligible for the menu
  var url = process.env.VUE_APP_ADMISSIONS_V1_API_ENDPOINT+"/verifyMenu";
  axios.post(url,{
  staffid:jwtDecode.staffid,
  menuPath:to.fullPath
 })
              .then(response => {
                if(response.data.code =='00'){
                  console.log(response.data.message);
                  if(response.data.message == true){
                    next()
                  }else{
                    window.location.href =`${window.location.origin}/#/auth`;
                  }
                }else{
                  window.location.href =`${window.location.origin}/#/auth`;
                  
                }
              })
              .catch(e => {
                window.location.href =`${window.location.origin}/#/auth`;
                alert('Something went wrong')
              })
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_vm._m(0),_c('div',{staticClass:" container-fluid",staticStyle:{"background":"#f5f5f5","margin-top":"180px"}},[(_vm.spaceanalysis =='')?_c('div',{staticClass:"menu_text py-2 px-4"},[_vm._v(" Loading space analysis .... ")]):_vm._e(),(_vm.spaceanalysis !='')?_c('div',{staticClass:"menu_text py-2 px-4"},[_c('div',{staticClass:"row"},_vm._l((_vm.spaceanalysis),function(spaces,index){return _c('div',{key:index,staticClass:"col-md-4"},[_c('v-card',{staticStyle:{"padding":"20px"}},[_vm._v(" Dept. : "+_vm._s(spaces.department)),_c('br'),_vm._v(" Space remaining. : "+_vm._s(spaces.spaces)+" ")])],1)}),0)]):_vm._e(),_vm._m(1),_c('div',{staticClass:" mb-10 pt-10 px-10 filter",staticStyle:{"background":"#fff"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.registration),expression:"registration"}],staticClass:"form-control",attrs:{"type":"text","id":"regno","placeholder":"Registration Number"},domProps:{"value":(_vm.registration)},on:{"input":function($event){if($event.target.composing){ return; }_vm.registration=$event.target.value}}})]),_c('div',{staticClass:"col-lg-6"},[_c('v-autocomplete',{attrs:{"clearable":"","item-text":"name","item-value":"_id","items":_vm.departments,"label":"Select Department"},model:{value:(_vm.department),callback:function ($$v) {_vm.department=$$v},expression:"department"}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Date From","append-icon":"mdi-calendar","readonly":"","outlined":"","clearable":""},model:{value:(_vm.fromdate),callback:function ($$v) {_vm.fromdate=$$v},expression:"fromdate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.fromto),callback:function ($$v) {_vm.fromto=$$v},expression:"fromto"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.fromdate),callback:function ($$v) {_vm.fromdate=$$v},expression:"fromdate"}})],1)],1),_c('div',{staticClass:"col-lg-6"},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Date To","append-icon":"mdi-calendar","readonly":"","outlined":"","clearable":""},model:{value:(_vm.todate),callback:function ($$v) {_vm.todate=$$v},expression:"todate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.tofrom),callback:function ($$v) {_vm.tofrom=$$v},expression:"tofrom"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.todate),callback:function ($$v) {_vm.todate=$$v},expression:"todate"}})],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-8"}),_c('div',{staticClass:"col-lg-4",staticStyle:{"text-align":"right"}},[_c('button',{staticClass:"next_button",on:{"click":function($event){return _vm.searchShop()}}},[_vm._v("Search")])])])]),(_vm.desserts !='')?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"table_clear"},[_c('v-card',[_c('v-card-title',[_c('h7',{staticClass:"table_title",staticStyle:{"color":"#666","padding-left":"15px"}},[_vm._v("Shopping List")]),_c('v-spacer'),_c('button',{staticClass:"download_b",attrs:{"type":"submit"},on:{"click":function($event){return _vm.csvExport(_vm.csvData)}}},[_c('span',{staticStyle:{"margin-right":"4px"}}),_vm._v(" Download")]),_c('v-pagination',{attrs:{"length":_vm.clearancepageCount},model:{value:(_vm.clearancepage),callback:function ($$v) {_vm.clearancepage=$$v},expression:"clearancepage"}})],1),_c('v-data-table',{staticClass:"px-5",attrs:{"page":_vm.clearancepage,"headers":_vm.headers,"items":_vm.desserts,"items-per-page":"100","search":_vm.search},on:{"update:page":function($event){_vm.clearancepage=$event}},scopedSlots:_vm._u([{key:"item.sn",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.desserts.indexOf(item)+1)+" ")]}},{key:"item.shop",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.department.name))])]}},{key:"item.surname",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.regNumberID.surname))])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.regNumberID.name))])]}},{key:"item.othernames",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.regNumberID.othernames))])]}},{key:"item.jambscore",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.regNumberID.total))])]}}],null,false,244435125)})],1)],1)])]):_vm._e()])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12",staticStyle:{"margin-top":"70px !important","height":"70px !important","padding":"30px 0 0px 10px","position":"fixed","z-index":"1","background":"#fff","border-bottom":"solid 1px #ccc"}},[_c('span',{staticStyle:{"color":"#777","font-size":"18px","padding":"10px"}},[_vm._v("Supplementary Shop Report")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"menu_text py-2 px-4",staticStyle:{"background":"#D6ECF5"}},[_c('h6',[_vm._v(" Select the Filter Field ")])])}]

export { render, staticRenderFns }
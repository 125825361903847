<template>
  <div >
      <div class="col_table">
                <v-card style="min-height:367px">
                    <v-card-title>
                        <h4 class="table_title">Departmental e-Clearance Analysis</h4>
                        <v-spacer></v-spacer>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <button v-bind="attrs" v-on="on" @click="csvExport(csvData)" class="download_b" type="submit"> <span style="margin-right: 4px"><img src="../../assets/download.svg" alt="" srcset=""></span> Download</button>
                            </template>
                            <span>Download The Record</span>
                        </v-tooltip>
                        <v-pagination v-model="studetList" :length="studetListpageCount"></v-pagination>
                    </v-card-title>
                     <v-row
        class="fill-height"
        align-content="center"
        justify="center" v-if="!dataLoaded"
      >
        <v-col
          class="text-subtitle-1 text-center"
          cols="12"
        >
         Sorting Departmental Clearance Data
        </v-col>
        <v-col cols="6">
          <v-progress-linear
            color="deep-purple accent-1"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </v-col>
      </v-row>
                    <v-data-table :page.sync="studetList" @page-count="studetListpageCount = $event" :headers="headers" :items="desserts" items-per-page="4" :search="search" class="px-5" v-if="dataLoaded"></v-data-table>
                </v-card>
            </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
data(){
    return{
        dataLoaded:false,
          headers: [{
                    text: 'Department',
                    align: 'start',
                    sortable: false,
                    value: 'department',
                    class: 'grey lighten-4'
                },
                {
                    text: 'Admitted students',
                    value: 'admitted',
                    class: 'grey lighten-4'
                },
                {
                    text: 'Cleared students',
                    value: 'cleared',
                    class: 'grey lighten-4'
                },
                {
                    text: 'Un-Cleared students',
                    value: 'uncleard',
                    class: 'grey lighten-4'
                },
            ],
            desserts: [{
                    department: 'Frozen Yogurt',
                    admitted: '190000',
                    cleared: '123889',
                    uncleard: '50228',
                },
                {
                    department: 'Frozen Yogurt',
                    admitted: '190000',
                    cleared: '123889',
                    uncleard: '50228',
                },
                {
                    department: 'Frozen Yogurt',
                    admitted: '190000',
                    cleared: '123889',
                    uncleard: '50228',
                },
                {
                    department: 'Frozen Yogurt',
                    admitted: '190000',
                    cleared: '123889',
                    uncleard: '50228',
                },
                {
                    department: 'Frozen Yogurt',
                    admitted: '190000',
                    cleared: '123889',
                    uncleard: '50228',
                },
                {
                    department: 'Frozen Yogurt',
                    admitted: '190000',
                    cleared: '123889',
                    uncleard: '50228',
                },
            ],

    }
},

    async beforeMount() {
        this.fetchData();
    },

 methods:{
    async fetchData() {
            const url = process.env.VUE_APP_ADMISSIONS_V1_API_ENDPOINT + '/getDeptClearAnalysis';
            const admissionAnalysis = await axios({
                method: 'POST',
                url: url,
                data: {
                    session: '2021-2022'
                }
            }).catch(e => {
                console.log(e);
            })
            if (admissionAnalysis.data.code == '00') {
                console.log(admissionAnalysis.data.data)
                this.loopData = admissionAnalysis.data.data
                this.dataLoaded = true
            }
        }
}
}
</script>

<style>

</style>
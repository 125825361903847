<template >
<div class="home">
    <div class="row">
        <div class="col-md-12" style="margin-top:70px !important; height:70px !important; padding:30px 0 0px 10px; position:fixed; z-index: 1; background:#fff; border-bottom: solid 1px #ccc">
            <span style="color:#777; font-size:18px;padding:20px">Admissions Dashboard</span> 
        </div>
    </div>
<div style="background: #f5f5f5; margin-top:180px" class="container-fluid">
<v-alert style="color:#ff735e"> <strong>UNDERGRADUATE REGULAR 2020/2021 ADMISSION</strong> <v-btn x-small style="margin-left:20px">Switch Program / Academic session</v-btn></v-alert>                
    <div class="row">
        <div class="col-lg-12">
        <router-view name="cardAnalytics"  style="height:258px"></router-view>
        </div>
        <!-- <div class="col-lg-3">
            <div class="first_child">
                <div style="display: flex; justify-content: space-between; padding: 10px" class="logo_txt">
                    <div class="txt">
                        <h4>Total Admited</h4>
                        <h2>{{displayData.admited.allcount}}</h2>
                    </div>
                    <div class="logo">
                        <img src="../assets/Group 283.svg" alt="" srcset="">
                    </div>
                </div>
                <div class="utme">
                    <h2>UTME : {{displayData.admited.jambCount}}</h2>
                </div>
                <div class="utme">
                    <h2>PS : {{displayData.admited.psCount}}</h2>
                </div>
                <div class="direct">
                    <h2>DIRECT ENTRY : {{displayData.admited.deCount}}</h2>
                </div>
            </div>
        </div>
        <div class="col-lg-3">
            <div class="second_child">
                <div style="display: flex; justify-content: space-between; padding: 10px" class="logo_txt">
                    <div class="txt">
                        <h4>Accepted Admisison</h4>
                        <h2>{{displayData.accepted.allcount}}</h2>
                    </div>
                    <div class="logo">
                        <img src="../assets/Group 282.svg" alt="" srcset="">
                    </div>
                </div>
                <div class="utme">
                    <h2>UTME : {{displayData.accepted.jambCount}}</h2>
                </div>
                <div class="utme">
                    <h2>PS : {{displayData.accepted.psCount}}</h2>
                </div>
                <div class="direct">
                    <h2>DIRECT ENTRY : {{displayData.accepted.deCount}}</h2>
                </div>
            </div>
        </div>
        <div class="col-lg-3">
            <div class="second_child">
                <div style="display: flex; justify-content: space-between; padding: 10px" class="logo_txt">
                    <div class="txt">
                        <h4>Submited Eclearance</h4>
                        <h2>{{displayData.fincleared.allcount}}</h2>
                    </div>
                    <div class="logo">
                        <img src="../assets/Group 282.svg" alt="" srcset="">
                    </div>
                </div>
                <div class="utme">
                    <h2>UTME : {{displayData.fincleared.jambCount}}</h2>
                </div>
                <div class="utme">
                    <h2>PS : {{displayData.fincleared.psCount}}</h2>
                </div>
                <div class="direct">
                    <h2>DIRECT ENTRY : {{displayData.fincleared.deCount}}</h2>
                </div>
            </div>
        </div>
        <div class="col-lg-3">
            <div class="third_child">
                <div style="display: flex; justify-content: space-between; padding: 10px" class="logo_txt">
                    <div class="txt">
                        <h4>Total Cleared</h4>
                        <h2>{{displayData.cleared.allcount}}</h2>
                    </div>
                    <div class="logo">
                        <img src="../assets/Group 279.svg" alt="" srcset="">
                    </div>
                </div>
                <div class="utme">
                    <h2>UTME : {{displayData.cleared.jambCount}}</h2>
                </div>
                <div class="utme">
                    <h2>PS : {{displayData.cleared.psCount}}</h2>
                </div>
                <div class="direct">
                    <h2>DIRECT ENTRY : {{displayData.cleared.deCount}}</h2>
                </div>
            </div>
        </div> -->
    </div>
    <div class="row">
        <div class="col-lg-8">
            <router-view></router-view>
        </div>
        <div class="col-lg-4">
            <div class="chart_class">
                <div class="pt-4 pl-4">
                    <h4>Demographic Analysis</h4>
                </div>
                <v-divider></v-divider>
                <div class="chart_one">
                    <h6 class="pl-4">Gender</h6>
                    <div id="chart">
                        <apexchart type="donut" width="250" :options="chartOptionsone" :series="seriesone"></apexchart>
                    </div>
                    <h6 class="pl-4">Special needs students</h6>
                    <div id="chart">
                        <apexchart type="donut" width="250" :options="chartOptionsTwo" :series="seriesTwo"></apexchart>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">
            <div class="bar_chart">
                <div id="chart">
                    <apexchart type="bar" height="430" :options="chartOptions" :series="series"></apexchart>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">
            <div class="table_clear">
                <v-card>
                    <v-card-title>
                        <h4 class="table_title">Admission Clearance</h4>
                        <v-spacer></v-spacer>
                        <button class="download_b" @click="csvExport2(csvData2)" type="submit"> <span style="margin-right: 4px">
                            <img src="../assets/download.svg" alt="" srcset="">
                            </span> Download</button>
                        <v-pagination v-model="clearancepage" :length="clearancepageCount"></v-pagination>
                    </v-card-title>
                    <v-data-table :page.sync="clearancepage" @page-count="clearancepageCount = $event" :headers="headersclearance" :items="dessertsclearance" items-per-page="4" :search="search" class="px-5"></v-data-table>
                </v-card>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">
            <div class="table_clear">
                <v-card>
                    <v-card-title>
                        <h4 class="table_title">Accepted Admission</h4>
                        <v-spacer></v-spacer>
                        <button @click="csvExport3(csvData3)" class="download_b" type="submit"> <span style="margin-right: 4px">
                            <img src="../assets/download.svg" alt="" srcset="">
                            </span> Download</button>
                        <v-pagination v-model="pageaccepted" :length="acceptedpageCount"></v-pagination>
                    </v-card-title>
                    <v-data-table :page.sync="pageaccepted" @page-count="acceptedpageCount = $event" :headers="acceptedheaders" :items="accepteddesserts" items-per-page="4" :search="search" class="px-5"></v-data-table>
                </v-card>
            </div>
        </div>
    </div>
</div>
               
</div>
</template>

<script>
import Swal from 'sweetalert2'

export default {
    data() {
        return {

            series: [{
                data: [44, 55, 41, 64, 22, 43, 21]
            }, {
                data: [53, 32, 33, 52, 13, 44, 32]
            }],
            chartOptions: {
                chart: {
                    colors: ['#4A869F'],
                    background: '#F4F7F9',
                    type: 'bar',
                    height: 430
                },
                legend: {
                    show: false,
                    showForSingleSeries: false,
                    customLegendItems: [],
                    markers: {
                        fillColors: ['#4A869F', '#775DD0']
                    }
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                        dataLabels: {
                            position: 'top',
                        },
                    }
                },
                dataLabels: {
                    enabled: false,
                    offsetX: -6,
                    style: {
                        fontSize: '12px',
                        colors: ['#fff']
                    }
                },
                stroke: {
                    show: true,
                    width: 3,
                    colors: ['#fff']
                },
                tooltip: {
                    shared: true,
                    intersect: false
                },
                xaxis: {
                    categories: [2001, 2002, 2003, 2004, 2005, 2006, 2007],
                },
            },

            // ANALYSIS
            seriesone: [44, 55],
            chartOptionsone: {
                chart: {
                    width: 250,
                    type: 'donut',
                },
                plotOptions: {
                    pie: {
                        startAngle: -90,
                        endAngle: 270
                    }
                },
                labels: ["Male", "Female"],
                dataLabels: {
                    enabled: false
                },
                fill: {
                    type: 'gradient',
                },
                legend: {
                    formatter: function (val, opts) {
                        return val + " - " + opts.w.globals.series[opts.seriesIndex]
                    }
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }]

            },
            seriesTwo: [44, 55],
            chartOptionsTwo: {
                chart: {
                    width: 250,
                    type: 'donut',
                },
                plotOptions: {
                    pie: {
                        startAngle: -90,
                        endAngle: 270
                    }
                },
                labels: ["Male", "Female"],
                dataLabels: {
                    enabled: false
                },
                fill: {
                    type: 'gradient',
                },
                legend: {
                    formatter: function (val, opts) {
                        return val + " - " + opts.w.globals.series[opts.seriesIndex]
                    }
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }]

            },
            search: '',
            studetList: 1,
            studetListpageCount: 0,
          
            // 
            clearancepage: 1,
            clearancepageCount: 0,
            headersclearance: [{
                    text: 'ID Number',
                    align: 'start',
                    sortable: false,
                    value: 'id_no',
                    class: 'grey lighten-4'
                },
                {
                    text: 'Surname',
                    value: 'surname',
                    class: 'grey lighten-4'
                },
                {
                    text: 'Name',
                    value: 'name',
                    class: 'grey lighten-4'
                },
                {
                    text: 'Othernames',
                    value: 'othernames',
                    class: 'grey lighten-4'
                },
                {
                    text: 'LGA',
                    value: 'lga',
                    class: 'grey lighten-4'
                },
                {
                    text: 'Stata',
                    value: 'state',
                    class: 'grey lighten-4'
                },
                {
                    text: 'Faculty',
                    value: 'faculty',
                    class: 'grey lighten-4'
                },
                {
                    text: 'Department',
                    value: 'cleared',
                    class: 'grey lighten-4'
                },
                {
                    text: 'Date Admitted',
                    value: 'date_admitted',
                    class: 'grey lighten-4'
                },
                {
                    text: 'status',
                    value: 'status',
                    class: 'grey lighten-4'
                },
            ],
            dessertsclearance: [],

            // 
            pageaccepted: 1,
            acceptedpageCount: 0,
            acceptedheaders: [{
                    text: 'ID Number',
                    align: 'start',
                    sortable: false,
                    value: 'id_no',
                    class: 'grey lighten-4'
                },
                {
                    text: 'Surname',
                    value: 'surname',
                    class: 'grey lighten-4'
                },
                {
                    text: 'Name',
                    value: 'name',
                    class: 'grey lighten-4'
                },
                {
                    text: 'Othernames',
                    value: 'othernames',
                    class: 'grey lighten-4'
                },
                {
                    text: 'LGA',
                    value: 'lga',
                    class: 'grey lighten-4'
                },
                {
                    text: 'Stata',
                    value: 'state',
                    class: 'grey lighten-4'
                },
                {
                    text: 'Faculty',
                    value: 'faculty',
                    class: 'grey lighten-4'
                },
                {
                    text: 'Department',
                    value: 'cleared',
                    class: 'grey lighten-4'
                },
                {
                    text: 'Date Admitted',
                    value: 'date_admitted',
                    class: 'grey lighten-4'
                },
                {
                    text: 'status',
                    value: 'status',
                    class: 'grey lighten-4'
                },
            ],
            accepteddesserts: [],
        }
    },
      beforeMount() {
        // this.admissionanalysis(this.$router.currentRoute.params.admissionAnalysis);
    },
created(){
        this.departmentName = this.$route.params.deptname
},
 mounted() {
        this.renderChart(this.chartdata, this.options)
    },
    computed: {
        csvData() {
            return this.desserts.map(item => ({
                ...item,
            }));
        },

        // Admission Clearance
        csvData2() {
            return this.dessertsclearance.map(item => ({
                ...item,
            }));
        },

        csvData3() {
            return this.accepteddesserts.map(item => ({
                ...item,
            }));
        }
    },
    methods: {

// admissionanalysis(x) {
//             this.loopData = x.data
//         },
        
getLAstUploaded(x){
   
  //const sorted = x.sort((a, b) => new Date(a.date_created) - new Date(b.date_created))
return x.slice(0, 1);
},
    theFormat(number) {
            return number;
        },
        theFormatm(number) {
            return this.formatPrice(number);
        },
        completed() {
            console.log('Animation ends!');
        },



        csvExport(arrData) {
            if (this.desserts.length == 0) {
                Swal.fire({
                    icon: "info",
                    text: "Table Is Empty",
                    width: 400,
                });
            } else {
                let csvContent = "data:text/csv;charset=utf-8,";
                csvContent += [
                        Object.keys(arrData[0]),
                        ...arrData.map(item => Object.values(item))
                    ]
                    // .trim()
                    .join("\n")
                    .replace(/(^\[)|(\]$)/gm, "");

                const data = encodeURI(csvContent);
                const link = document.createElement("a");
                link.setAttribute("href", data);
                link.setAttribute("download", "studentlist.csv");
                link.click();
            }
        },

        csvExport2(arrData2) {
            if (this.dessertsclearance.length == 0) {
                Swal.fire({
                    icon: "info",
                    text: "Table Is Empty",
                    width: 400,
                });
            } else {
                let clearancecsvContent = "data:text/csv;charset=utf-8,";
                clearancecsvContent += [
                        Object.keys(arrData2[0]),
                        ...arrData2.map(item => Object.values(item))
                    ]
                    // .trim()
                    .join("\n")
                    .replace(/(^\[)|(\]$)/gm, "");

                const data = encodeURI(clearancecsvContent);
                const link = document.createElement("a");
                link.setAttribute("href", data);
                link.setAttribute("download", "clearance.csv");
                link.click();
            }
        },

        csvExport3(arrData) {
            if (this.accepteddesserts.length == 0) {
                Swal.fire({
                    icon: "info",
                    text: "Table Is Empty",
                    width: 400,
                });
            } else {
                let csvContent = "data:text/csv;charset=utf-8,";
                csvContent += [
                        Object.keys(arrData[0]),
                        ...arrData.map(item => Object.values(item))
                    ]
                    // .trim()
                    .join("\n")
                    .replace(/(^\[)|(\]$)/gm, "");

                const data = encodeURI(csvContent);
                const link = document.createElement("a");
                link.setAttribute("href", data);
                link.setAttribute("download", "studentlist.csv");
                link.click();
            }
        }
    },
}
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
.first_txt {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    color: #4F4F4F;
    margin: 20px 0;
}

.bar_chart {
    width: 100%;
    height: auto;
    background: #FFFFFF;
    border-radius: 6px;
    padding: 20px 0;
}

.chart_class {
    width: 100%;
    height: auto;
    background: #FFFFFF;
    border-radius: 8px;
    padding-bottom: 2px;

    h4 {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #4F4F4F;
    }

    h6 {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 12px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #646262;
    }
}

#chart {
    background: #FFFFFF;
    border: 1px solid #F0F2F3;
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    margin: 20px;
}

.download_b {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 105px;
    height: 28px;
    background: #D6ECF5;
    border-radius: 3px;

    font-family: Proxima Nova;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    color: #006794;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0px 5px;
}
</style>
